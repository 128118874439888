<template>
  <div>
    <el-drawer class="serviceDeployDetail-drawer" title="店铺客服调配详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div>
              <div class="detail-title">
                <div class="tit-info">
                    <div class="shopLogo-container">
                      <img v-if="shopDetail.shopLogo" :src="shopDetail.shopLogo" alt="">
                      <i v-else class="el-icon-user common-iconImg-MR"></i>
                    </div>
                    <div class="shopName-custName">
                      <span>{{ shopDetail.shopName || "--" }}</span>
                      <span>{{ shopDetail.customerName || "--" }}</span>
                    </div>
                    <div v-if="shopDetail.warningStatus == '0'" class="common-tableStatus-blue">咨询正常</div>
                    <div v-if="shopDetail.warningStatus == '1'" class="common-tableStatus-red">咨询爆量</div>
                </div>
                <div class="handle-btn" @click="() => { handleShow() }">增减人力</div>
              </div>
              <div class="detail-content">
                  <div class="detail-item">平台：<span>{{shopDetail.platformName || '--'}}</span></div>
                  <div class="detail-item">类目：<span>{{shopDetail.categoryName || '--'}}</span></div>
                  <div class="detail-item">客服人数：<span>{{shopDetail.serviceCount || shopDetail.serviceCount == 0 ? shopDetail.serviceCount : '--'}}</span></div>
                  <div class="detail-item">子账号数量：<span>{{shopDetail.subaccountCount || shopDetail.subaccountCount == 0 ? shopDetail.subaccountCount : '--'}}</span></div>
                  <div class="detail-item">咨询量：<span>{{shopDetail.consultationNum || shopDetail.consultationNum == 0 ? shopDetail.consultationNum : '--'}}</span></div>
                  <div class="detail-item">运营负责人：<span>{{shopDetail.busineseUser || '--'}}</span></div>
              </div>
              <div class="detail-remark"><span>备注：</span> <span>{{shopDetail.note || '--'}}</span></div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">上传附件<input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteShopFile(item)">
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane label="带店客服" name="service">
              <div class="service-table-container" style="max-height: max-content;">
                <div class="table-title">
                  <div>当前关联客服数：{{shopDetail.serviceCount || shopDetail.serviceCount == 0 ? shopDetail.serviceCount : '0'}} 人</div>
                  <div>已分配子账号数量：{{shopDetail.disSubaccountCount || 0}} 个</div>
                  <div>咨询量: {{shopDetail.consultationNum || shopDetail.consultationNum == 0 ? shopDetail.consultationNum : '0'}} 个</div>
                </div>
                <div class="table-content" v-if="serviceSubTab && serviceSubTab.length">
                  <div class="table-item" style="height: auto;" v-for="(item, index) in serviceSubTab" :key="index">
                    <div>【{{item.nickName || '--'}}】 {{item.subaccountName}}</div>
                    <div>{{item.shopConsultationNum || 0}}个</div>
                  </div>
                </div>
                <div class="table-content" v-else>
                  <div class="table-item">
                    <div>暂无客服</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="baseInfo">
              <div class="detail-content">
                <div class="DisableEdit">创建人: <span class="common-clr-0a6">{{(shopDetail && shopDetail.createName) || "--"}}</span></div>
                <div class="DisableEdit">创建时间: <span class="common-clr-0a6">{{(shopDetail && shopDetail.createTime) || "--"}}</span></div>
                <div class="DisableEdit">最后修改人: <span class="common-clr-0a6">{{(shopDetail && shopDetail.updateName) || "--"}}</span></div>
                <div class="DisableEdit">最后修改时间: <span class="common-clr-0a6">{{(shopDetail && shopDetail.updateTime) || "--"}}</span></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
              <div>
                <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="工作小计"></el-input>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button :loading="btnLoading" @click="() => {shopAddLog()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog  v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="增减人力" :append-to-body="true" width="620px" :visible.sync="handleVisible" :close-on-click-modal="false">
      <el-form :model="ruleFormHandle" :rules="ruleHandleRules" ref="ruleFormHandle" label-width="130px" class="demo-ruleForm">
        <el-form-item label="已分配子账号数：">
          <span>{{ruleFormHandle.subAccountOldNum || 0}} 个</span>
        </el-form-item>
        <el-form-item label="增减类型：" prop="type">
           <el-radio-group @change="(val) => { deployChange(val) }" v-model="ruleFormHandle.type">
            <el-radio label="1">增加人力</el-radio>
            <el-radio label="2">削减人力</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择客服：">
          <div class="service-table-container">
            <div class="table-title">
              <div>客服名称</div>
              <div>总咨询量</div>
            </div>
            <!-- <div class="table-content" v-if="serviceData && serviceData.length">
              <div class="table-item" v-for="(item, index) in serviceData" :key="index">
                <div><el-checkbox v-model="checked">【{{item.nickName || '--'}}】</el-checkbox></div>
                <div>{{item.consultationNum || 0}}个</div>
              </div>
            </div> -->
            <div class="table-content" v-if="serviceData && serviceData.length">
              <div class="table-item" v-for="(item, index) in serviceData" :key="index">
                <div class="item-name" @click="() => {subShow(item.nickName ,item.adminId)}">【{{item.nickName || '--'}}】</div>
                <div>{{item.consultationNum || 0}}个</div>
              </div>
            </div>
            <div class="table-content" v-else>
              <div class="table-item">
                <div>暂无客服</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="时间段：" prop="dateArr">
          <el-date-picker class="common-screen-input_70" @change="(val) => { changeTime(val) }" v-model="ruleFormHandle.dateArr" type="datetimerange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          <span>（{{ruleFormHandle.hourNum || 0}}）小时</span>
        </el-form-item>
        <el-form-item label="增减子账号数量：">
          <div class="number-container">
            <div><el-input class="number-100" disabled type="text" :value="ruleFormHandle.subAccountChangeNum"></el-input>个</div>
            <div>增减结果:{{ruleFormHandle.subAccountNewNum || 0}}个</div>
          </div>
        </el-form-item>
        <el-form-item label="客服增减明细：">
          <div class="service-table-container">
            <div class="table-title">
              <div>客服子账号明细</div>
            </div>
            <div class="table-content" v-if="subMultiple && subMultiple.length">
              <div class="table-item" v-for="(item, index) in subMultiple" :key="index">
                <div>【{{item.serviceName}}】 {{item.subName}}</div>
                <div><i class="el-icon-circle-close" @click="() => { deleteSub(index) }"></i></div>
              </div>
            </div>
            <div class="table-content" v-else>
              <div class="table-item">
                <div>暂无调配</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="通知人：" prop="msgAdmins">
          <el-select class="common-form-input_100" v-model="ruleFormHandle.msgAdmins" placeholder="请选择通知人" filterable multiple>
              <el-option v-for="item in userList" :key="item.adminId + ''" :label="item.nickName" :value="item.adminId + ''"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="增减人力说明：">
          <el-input class="common-form-input_100" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="ruleFormHandle.note" placeholder="建议详细填写增减人力说明"></el-input>
        </el-form-item>
      
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="handleVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => { handleOk() }">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="`子账号管理-${ruleFormHandle.type == '1' ? '增加人力':'削减人力'}`" :append-to-body="true" width="50%" :visible.sync="subVisible" :close-on-click-modal="false">
      <el-button class="common-screen-btn" v-if="ruleFormHandle.type == '1'" type="primary" @click="addEdit()">新建子账号</el-button>
      <el-table v-loading="subLoading" :header-cell-class-name="cellClass" :data="subTableData" @select="handleSelectionChange" select-on-indeterminate class="common-table">
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column width="120px" :formatter="tableColumn" prop="subaccountName" label="子账号名称"></el-table-column>
        <el-table-column width="120px" :formatter="tableColumn" prop="subaccountPassword" label="子账号密码"> </el-table-column>
        <el-table-column width="120px" :formatter="tableColumn" prop="phone" label="管理员手机"> </el-table-column>
        <el-table-column width="120px" :formatter="tableColumn" prop="nickName" label="指派客服"> </el-table-column>
        <el-table-column label="服务时间" width="140px">
          <template slot-scope="scope">
            <div style="white-space: pre-line">
              {{
                `${DateTransform(scope.row.startTime)}\n${DateTransform(scope.row.endTime)}`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140px" :formatter="tableColumn" prop="note" label="备注"> </el-table-column>
        <el-table-column width="160px" :formatter="tableColumn" prop="createName" label="创建人"> </el-table-column>
        <el-table-column width="180px" :formatter="tableColumn" prop="createTime" label="创建时间"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" type="primary" @click="() => { subVisible = false }">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="新建子账号"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
          <el-form-item label="子账号名称" prop="subaccountName">
            <el-input class="dialog-input" placeholder="请填写子账号名称" v-model="ruleFormAdd.subaccountName" maxLength="50" ></el-input>
          </el-form-item>
          <el-form-item label="子账号密码" prop="subaccountPassword">
            <el-input class="dialog-input" placeholder="请填写子账号密码" v-model="ruleFormAdd.subaccountPassword" maxLength="50" ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" prop="phone">
            <el-input class="dialog-input" placeholder="请填写管理员手机号" v-model="ruleFormAdd.phone" maxLength="50"></el-input>
          </el-form-item>
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker v-model="ruleFormAdd.datePicker" class="dialog-input" type="daterange" :clearable="false" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" ></el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" class="dialog-input" placeholder="请填写备注" v-model="ruleFormAdd.note" maxLength="500"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { Config, tableColumn, DateTransform, TimeTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { serviceDeployDetail, shopAddLog, deployUpload, deployDelete, serviceList, adminList, shopSubList, commitSubDetail, addEditSubaccount, deployService } from "../../../service/customer.js"
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import { workLog }  from "../../../service/common.js"
export default {
  props: ["drawer", "handleClose", "companyId"],
  components: { CommonPicture, CommonWorkLog },
  data() {
    return {
      DateTransform,
      loading: false,
      subLoading: false,
      Config,
      tableColumn,
      fileList:[],
      activeName: "appeal", // tabs
      activeNameLeft: "service", // 左侧tabs
      recordContent: '', // 工作小计
      filesList: [],  // 工作小计文件上传
      shopDetail: {}, // 列表一行信息
      followRecordList: [], // 工作日志
      handleVisible: false,
      ruleFormHandle: {
        subAccountOldNum: '', // 原子账号数量
        subAccountChangeNum: 0, // 增减子账号数量
        subAccountNewNum: '', // 增减结果
        msgAdmins: [], // 通知人
        note: '', // 增减说明
        type: '1', // 增减类型
      },
      serviceData: [], // 当天客服列表
      userList: [{nickName: '111', adminId: 1},{nickName: '222', adminId: 2},{nickName: '333', adminId: 3},],
      ruleHandleRules: {
        dateArr: [{ required: true, message: "不能为空", trigger: "change" }],
        type: [{ required: true, message: "不能为空", trigger: "change" }],
        msgAdmins: [{ required: true, message: "不能为空", trigger: "change" }],
      },
      subVisible: false,
      subTableData: [],
      subMultiple: [], // 增减人力客服
      serviceInfo: {serviceName: '', serviceId: ''}, // 临时id，用来存储分配子账号时候，绑定id
      serSubNUm: 0,
      serviceSubTab: [],
      centerDialogVisible: false, // 新增子账号弹窗
      ruleFormAdd: {},
      rules: {
        subaccountName: [{ required: true, message: "请填写子账号名称", trigger: "change" }],
        subaccountPassword: [{ required: true, message: "请填写子账号密码", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    async deployService(type) { // 客服列表
      let { data } = await deployService({type, shopId: this.shopDetail.shopId, companyId: this.companyId})
      this.serviceData = data
    },
    submitForm(formName) { // 新增提交
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let ruleFormAdd = { ...this.ruleFormAdd };
          ruleFormAdd.shopId = this.shopDetail.shopId
          if (ruleFormAdd.datePicker && ruleFormAdd.datePicker.length) {
            ruleFormAdd.startTime = DateTransform(ruleFormAdd.datePicker[0]);
            ruleFormAdd.endTime = DateTransform(ruleFormAdd.datePicker[1]);
            delete ruleFormAdd.datePicker
          }
          ruleFormAdd.companyId = this.companyId
          this.loading = true
          let { code } = await addEditSubaccount(ruleFormAdd);
          this.loading = false
          if ( code == 200 ) { 
            this.$message.success("添加成功");
            this.serviceDeployDetail(this.shopDetail.shopId)
            this.workLog(this.shopDetail.shopId)
            if (this.ruleFormHandle.type == '1') {
              this.shopSubList(2)
            } else {
              this.shopSubList(1)
            }
            this.centerDialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    addEdit() { // 新增弹窗
      this.ruleFormAdd = {
        shopId: this.shopDetail.shopId,
        datePicker: [
          new Date(this.$route.query.startTime),
          new Date(this.$route.query.endTime),
        ],
      };
      if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      this.centerDialogVisible = true;
    },
    deployChange() {
      this.subMultiple = []
      this.ruleFormHandle.subAccountChangeNum = 0
      this.ruleFormHandle.subAccountNewNum = this.ruleFormHandle.subAccountOldNum || 0
      if (this.ruleFormHandle.type == '1') {
        this.deployService(2)
      } else {
        this.deployService(1)
      }
      
    },
    changeTime(val) { // 时间选择
      this.ruleFormHandle = { ...this.ruleFormHandle, hourNum:(((new Date(val[1])).getTime() - (new Date(val[0])).getTime()) / (1000 * 60 * 60)).toFixed(2) }
    },
    deleteSub (i) { // 删除选中的子账号
      let subMultiple = [...this.subMultiple]
      subMultiple = subMultiple.filter((item, index) => { return i != index })
      this.subMultiple = subMultiple
      this.ruleFormHandle.subAccountChangeNum = subMultiple.length
      if (this.ruleFormHandle.type == '1') {
        this.ruleFormHandle.subAccountNewNum = subMultiple.length + Number(this.ruleFormHandle.subAccountOldNum)
      } else {
        this.ruleFormHandle.subAccountNewNum = Number(this.ruleFormHandle.subAccountOldNum) - subMultiple.length
      }
    },
    cellClass(row){ // 隐藏表头全选
      if (row.columnIndex === 0) {
        return 'DisableSelection'
      }
    },
    handleSelectionChange(allVal, val) {
      let subMultiple = [...this.subMultiple]
      let isChoose = false
      allVal.map((item) => {
        if (item.subAccountId == val.subAccountId) {
          isChoose = true
          return
        }
      })
      if (isChoose) {
        subMultiple.push({ ...this.serviceInfo, subId: val.subAccountId, subName: val.subaccountName})
      } else {
        subMultiple = subMultiple.filter((item) => {
          return item.subId != val.subAccountId
        })
      }
      if (this.ruleFormHandle.type == '1') {
        this.ruleFormHandle.subAccountNewNum = subMultiple.length + Number(this.ruleFormHandle.subAccountOldNum)
      } else {
        this.ruleFormHandle.subAccountNewNum = Number(this.ruleFormHandle.subAccountOldNum) - subMultiple.length
      }
      this.ruleFormHandle.subAccountChangeNum = subMultiple.length
      this.subMultiple = subMultiple
    },
    subShow(name, id) {
      this.serviceInfo = {
        serviceName:name,
        serviceId: id
      }
      if (this.ruleFormHandle.type == '1') {
        this.shopSubList(2)
      } else {
        this.shopSubList(1, id)
      }
      this.subVisible = true
    },
    async serviceSubList(id) { // 查询客服带店列表
      this.loading = true
      let { data } = await deployService({shopId: id, type: 1, companyId: this.companyId })
      this.loading = false
      this.serviceSubTab = data || []
      this.serSubNUm = data.length
    },
    async shopSubList( type, adminId ) { // 查询店铺子账号列表
      let params = {
        type: type,
        shopId: this.shopDetail.shopId,
        companyId: this.companyId
      }
      if(adminId) {
        params.adminId = adminId
      } else {
        delete params.adminId
      }
      this.subLoading = true
      let { data } = await shopSubList(params)
      this.subLoading = false
      let newArr = JSON.parse(JSON.stringify(data.list))
      let subMultiple = JSON.parse(JSON.stringify(this.subMultiple))
      if(data.list && data.list.length) {
        data.list.map((item) => {
          subMultiple.map((itemTwo) => {
            if (item.subAccountId == itemTwo.subId) {
              // newArr.map((itemThree) => {
              //   if (itemTwo.subId == itemThree.subAccountId) {
              //     itemThree.disabled = true
              //   } else {
              //     itemThree.disabled = false
              //   }
              // })
              newArr = newArr.filter((itemThree) => {
                return itemTwo.subId != itemThree.subAccountId
              })
            }
          })
        })
        this.subTableData = newArr
      } else {
        this.subTableData = []
      }
    },
    async adminList() { // 除客服外所有人下拉
      let { data } = await adminList({companyId: this.companyId})
      this.userList = data
    },
    async serviceList (id) { // 增减人力-弹窗客服列表
      let { data } = await serviceList({shopId: id, companyId: this.companyId})
      // this.serviceData = data.serviceList
      this.ruleFormHandle.subAccountOldNum = data.subAccountNum
      this.ruleFormHandle.subAccountNewNum = data.subAccountNum
      this.ruleFormHandle.msgAdmins = data.adminStr.split(',')
    },
    download(url) { // 下载
      window.location.href = url
    },
    async deleteShopFile(item) { // 删除附件
      let params = {
        shopId: this.shopDetail.shopId,
        filedId: item.id,
        filedUrl: item.url,
        fileName: item.name
      }
      this.loading = true
      let { code } = await deployDelete(params)
      this.loading = false
      if (code == 200) {
        this.$message.success('删除成功')
        this.serviceDeployDetail(this.shopDetail.shopId)
        this.workLog(this.shopDetail.shopId)
      }
    },
    async uploadFile(e) { // 上传附件
        // let fileList = [...this.fileList]
        // if (fileList && fileList.length) {
        //     e.target.value = "";
        //     return this.$message.error('交接文档不能重复上传，请删除后重新上传')
        // }
        let file = e.target.files[0];
        // let fileName = file.name;
        let format = file.name.split('.')[ file.name.split('.').length - 1];
        if (file.size / 1024 > 1024 * 50) {
            e.target.value = "";
            return this.$message.error('文件大小不能超过50M')
        }
        if (!Config.fileType.PWEIType.includes(format)) {
            e.target.value = "";
            return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
        }
        this.loading = true
        let resData = (await upload({ file: file })).data;
        console.log(this.shopDetail.shopId)
        let { code } = await deployUpload({shopId: this.shopDetail.shopId, filedId: resData.id, filedUrl: resData.filename, fileName: file.name})
        if (code == 200) {
            this.$message.success('上传成功')
        }
        e.target.value = ''
        this.serviceDeployDetail(this.shopDetail.shopId)
        this.workLog(this.shopDetail.shopId)
        this.loading = false
    },
    async shopAddLog() { // 工作记录发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
        shopId: this.shopDetail.shopId,
        content: this.recordContent,
        fileIds: filesId.join(','),
      };
      this.loading = true
      let { code } = await shopAddLog(params)
      this.loading = false
      if (code == 200) {
        this.workLog(this.shopDetail.shopId)
        this.filesList = []
        this.recordContent = "";
        this.$message.success("添加成功");
      }
    },
    async handleOk() {  // 确认调配
      this.$refs['ruleFormHandle'].validate(async (valid) => {
        if (valid) { // 发送请求
          let ruleFormHandle = {...this.ruleFormHandle}
          let subMultiple = [...this.subMultiple]
          ruleFormHandle.shopId = this.shopDetail.shopId
          if(ruleFormHandle.dateArr && ruleFormHandle.dateArr.length) {
            ruleFormHandle.startTime = TimeTransform(ruleFormHandle.dateArr[0])
            ruleFormHandle.endTime = TimeTransform(ruleFormHandle.dateArr[1])
            delete ruleFormHandle.dateArr
          }
          let serviceDeployIdArr = []
          let relationIds = []
          subMultiple.map((item) => {
            serviceDeployIdArr.push(`${item.serviceId},${item.subId}`)
            relationIds.push(`${item.relationId}`)
          })
          ruleFormHandle.serviceDeployIdArr = serviceDeployIdArr
          ruleFormHandle.msgAdmins = ruleFormHandle.msgAdmins.join(',')
          if(ruleFormHandle.type == '2') {
            ruleFormHandle.relationIds = relationIds.join(',')
          } else {
            delete ruleFormHandle.relationIds
          }
          this.loading = true
          let { code } = await commitSubDetail(ruleFormHandle)
          this.loading = false
          if (code == 200) {
            this.$message.success("处理成功")
            this.handleVisible = false
            this.workLog(this.shopDetail.shopId)
            this.serviceDeployDetail(this.shopDetail.shopId)
            this.serviceSubList(this.shopDetail.shopId)
          }
        } else {
          return false
        }
      });
    },
    handleShow () { // 增减人力
      
      this.ruleFormHandle = {
        subAccountOldNum: 0, // 原子账号数量
        subAccountChangeNum: 0, // 增减子账号数量
        subAccountNewNum: 0, // 增减结果
        msgAdmins: [], // 通知人
        note: '', // 增减说明
        type: '1', // 增减类型
      }
      this.deployService(2)
      this.serviceList(this.shopDetail.shopId)
      if(this.$refs["ruleFormHandle"]) {
        this.$refs["ruleFormHandle"].resetFields();
      }
      this.handleVisible = true
    },
    tableParams (row) { // 获取列表的值
      this.serviceDeployDetail(row.shopId)
      this.workLog(row.shopId)
      this.serviceSubList(row.shopId)
      this.adminList()
    },
    async serviceDeployDetail (id) {
      let { data } = await serviceDeployDetail({shopId: id, companyId: this.companyId})
      if (data.fileList && data.fileList.length) {
        let fileList = []
        data.fileList.map((item) => {
          fileList.push({url: item.filedUrl, name: item.filedName, id: item.filedId})
        })
        this.fileList = fileList
      } else {
        this.fileList = []
      }
      this.shopDetail = data
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 5})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...resData]
    },
    deleteFile (id) { // 删除申诉文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e) { // 发起申诉选择文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
  },
};
</script>
<style lang="less" scoped>
// 隐藏全选框
/deep/ .DisableSelection > .cell {
  display: none !important;
}
/deep/.el-checkbox__inner::after {
  left: 0;
  right: 0;
  margin: auto;
}
.number-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .number-100 {
    width: 80px;
    margin-right: 10px;
  }
}
.service-table-container {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #bbb;
  border-radius: 4px;
  max-height: 262px;
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
    .table-item {
      height: 52px;
      line-height: 52px;
      padding: 0 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #bbb;
      .item-name {
        cursor: pointer;
        color: #1890FF;
      }
      .el-icon-circle-close {
        cursor: pointer;
        font-size: 18px;
        &:hover {
          color: #1890FF;
        }
      }
    }
  }
  .table-title {
    width: 100%;
    box-sizing: border-box;
    color: #101010;
    background: #f2f2f2;
    border-radius: 4px 4px 0 0;
    padding: 0 24px;
    height: 52px;
    line-height: 52px;
    display: flex;
    justify-content: space-between;
  }
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
    font-size: 12px;
    color: #333;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
    .deleteBtn {
    color: #1890ff;
    cursor: pointer;
    }
  }
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border:  1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      font-size: 0;
    }
  } 
}
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.serviceDeployDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .upload-container {
        margin-top: 48px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .detail-title {
        line-height: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0;
        .tit-info {
            display: flex;
            align-items: center;
            .shopLogo-container {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                img {
                    width: 100%;
                }
                .common-iconImg-MR {
                    width: 64px;
                    height: 64px;
                    font-size: 50px;
                    line-height: 64px;
                    display: block;
                }
            }
            .shopName-custName {
                display: inline-block;
                margin: 0 16px;
                span {
                    display: block;
                    line-height: 24px;
                    &:nth-of-type(1) {
                        color: #333;
                        font-size: 18px;
                    }
                    &:nth-of-type(2) {
                        color: #999;
                        font-size: 14px;
                    }
                }
            }
            .orderState {
                padding: 2px 8px;
                border-radius: 4px;
                border: 1px solid #F98787;
                color: #F98787;
                margin-left: 16px;
            }
        }
        .handle-btn {
          width: 100px;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          color: #fff;
          background: #1890FF;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          cursor: pointer;
        }
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
        .DisableEdit {
        display: inline-block;
        width: 323px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
        .chatItem {
          color: #101010;
          font-size: 14px;
          margin-top: 8px;
          .chatItem-title {
            display: flex;
            justify-content: space-between;
            line-height: 38px;
          }
          .chatItem-content {
            line-height: 38px;
            background: #F7F8FB;
            padding-left: 10px;
          }
          .chartItem-img {
            width: 80px;
          }
        }
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
}

</style>
